import { LanguageModel, LanguageName } from '../types';

const language: LanguageModel = {
  languageInfo: {
    name: LanguageName.English,
  },
  languages: {
    polish: 'Polish',
    english: 'English',
  },
  sections: {
    socialMedia: 'Social Media',
    abilities: 'Abilities',
    languages: 'Languages',
    experience: 'Experience',
    education: 'Education',
    achievements: 'Achievements',
    additionSkills: 'Addition Skills',
    hobby: 'Hobby',
  },
  personalData: {
    name: 'Michał',
    surname: 'Grzeżułkowski',
    position: 'Senior Software Developer',
  },
  experience: {
    barak: {
      workPosition: 'President of the Barak scientific club',
      companyName: 'Barak',
      jobDescription: 'Design, construction and software development of a solar heliostat model.',
    },
    astor: {
      workPosition: 'Professional practice',
      companyName: 'Astor',
      jobDescription:
        'Programming of PLC controllers, programming of EPSON industrial robots.\nExecution of two industrial orders for EPSON robot software for external companies.',
    },
    kimla: {
      workPosition: 'Electronics assistant, apprentice',
      companyName: 'Kimla',
      jobDescription: 'Designing electronic circuits for processing signals from sensors in a CNC machine tool.',
    },
    tieto: {
      workPosition: 'Junior Software Engineer',
      companyName: 'Tieto Poland',
      jobDescription:
        'Developing the Bluetooth Low Energy SDK, creating profiles and services, as well as Bluetooth applications. Creating automated tests (C / Python). Work in an international environment. Direct contact with client. Work in the GIT version control system (Gerrit) in the Scrumban method. IDE for C: Eclipse, for python: PyCharm',
    },
    nokiaLte: {
      workPosition: 'C++/Python Software Developer',
      companyName: `Sii ${String.fromCharCode(8594)} Nokia`,
      jobDescription: 'Developing the SDK of one of the LTE layers.\nC++, Python',
    },
    publishThis: {
      workPosition: 'React Software Developer',
      companyName: `Sii ${String.fromCharCode(8594)} Publish This`,
      jobDescription:
        'FrontEnd (React) Developer. The main person responsible for the architecture of the Javascript part of the web application.',
      jobDescriptionBolded: 'FULLY REMOTE (office in Los Angeles)',
    },
    nokiaReact: {
      workPosition: 'Senior React Software Developer',
      companyName: `Sii ${String.fromCharCode(8594)} Nokia`,
      jobDescription:
        'Senior FrontEnd (React) Developer. The main person responsible for architecture, review of the JavaScript (Typescript) code. One of person responsible for talking about new feature requirements. Conducting recruitment processes for the position of Junior / Mid / Senior / Architect React Developer.',
      jobDescriptionBolded: 'FULLY REMOTE',
    },
    sii: {
      workPosition: 'Senior React Software Developer',
      companyName: 'Sii',
      jobDescription:
        'Software development for external companies. Conducting training in programming and useful IT project management tools – only small private for now. Conducting recruitment processes for the position of Architect / Senior / Mid / Junior React Developer.',
      jobDescriptionBolded: 'FULLY REMOTE',
    },
    speedApp: {
      workPosition: 'Senior React Software Developer',
      companyName: 'Speed App',
      jobDescription: 'Software development for external companies.',
      jobDescriptionBolded: 'FULLY REMOTE',
    },
    sternum: {
      workPosition: 'Senior React Software Developer',
      companyName: 'Sternum',
      jobDescription: 'Senior FrontEnd (React) Developer. Developing an application written in Electron.',
      jobDescriptionBolded: 'FULLY REMOTE',
    },
  },
  achievements: {
    sep: 'SEP E1 entitlement to 1 kV',
    cladCertificateLabView: 'CLAD certificate from National Instruments on LabVIEW programming',
    microboticsSummerWorkshop: 'Participation in the Polish-Spanish robotic workshop Microbotics Summer Workshop',
    laureatePhysicsAndEcology:
      'Laureate of the 16th National Competition for the Work "Physics and Ecology" for the work "Natural Radioactivity of Soils"',
    youngScientists: 'Participation in the National Conference of Young Scientists',
  },
  additionSkills: {
    projectManagementTechniques: 'Knowledge of project management techniques (Scrum, Kanban, Scrumban, Waterfall)',
    drivingLicense: 'Category B driving license',
  },
  hobby: {
    creatingWebsites: 'Designing and creating websites - web applications',
    homeManagementSystem: 'Designing a home management system',
    readingSelfDevBooks: 'Reading self-development books',
    dancing: 'Dancing',
    photography: 'Photography',
  },
  common: {
    toNow: 'until now',
    educationDepartment: 'Department',
    educationFieldOfStudy: 'Field of study',
  },
  education: {
    engineer: {
      school: 'Wroclaw University of Technology',
      degree: 'Engineer',
      department: 'Electronics',
      fieldOfStudy: 'Automation and Robotics',
    },
    master: {
      school: 'Wroclaw University of Technology',
      degree: 'Master of Science in Engineering',
      department: 'Electronics',
      fieldOfStudy: 'Automation and Robotics',
    },
  },
  cvClause: '',
};

export default language;
