import { LanguageGetter } from '../../language';
import { MainContentData } from './types';

export const getBasicInfoData = (language: LanguageGetter): MainContentData => ({
  experience: [
    {
      companyName: language((l) => l.experience.sternum.companyName),
      workPosition: language((l) => l.experience.sternum.workPosition),
      jobDescription: language((l) => l.experience.sternum.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.sternum.jobDescriptionBolded),
      fromDate: '02.2022',
      toDate: language((l) => l.common.toNow),
    },
    {
      companyName: language((l) => l.experience.speedApp.companyName),
      workPosition: language((l) => l.experience.speedApp.workPosition),
      jobDescription: language((l) => l.experience.speedApp.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.speedApp.jobDescriptionBolded),
      fromDate: '02.2022',
      toDate: language((l) => l.common.toNow),
    },
    {
      companyName: language((l) => l.experience.sii.companyName),
      workPosition: language((l) => l.experience.sii.workPosition),
      jobDescription: language((l) => l.experience.sii.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.sii.jobDescriptionBolded),
      fromDate: '02.2018',
      toDate: '02.2022',
    },
    {
      companyName: language((l) => l.experience.nokiaReact.companyName),
      workPosition: language((l) => l.experience.nokiaReact.workPosition),
      jobDescription: language((l) => l.experience.nokiaReact.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.nokiaReact.jobDescriptionBolded),
      fromDate: '07.2020',
      toDate: '02.2022',
    },
    {
      companyName: language((l) => l.experience.publishThis.companyName),
      workPosition: language((l) => l.experience.publishThis.workPosition),
      jobDescription: language((l) => l.experience.publishThis.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.publishThis.jobDescriptionBolded),
      fromDate: '09.2018',
      toDate: '07.2020',
    },
    {
      companyName: language((l) => l.experience.nokiaLte.companyName),
      workPosition: language((l) => l.experience.nokiaLte.workPosition),
      jobDescription: language((l) => l.experience.nokiaLte.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.nokiaLte.jobDescriptionBolded),
      fromDate: '02.2018',
      toDate: '09.2018',
    },
    {
      companyName: language((l) => l.experience.tieto.companyName),
      workPosition: language((l) => l.experience.tieto.workPosition),
      jobDescription: language((l) => l.experience.tieto.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.tieto.jobDescriptionBolded),
      fromDate: '07.2015',
      toDate: '02.2018',
    },
    {
      companyName: language((l) => l.experience.kimla.companyName),
      workPosition: language((l) => l.experience.kimla.workPosition),
      jobDescription: language((l) => l.experience.kimla.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.kimla.jobDescriptionBolded),
      fromDate: '07.2014',
      toDate: '08.2014',
    },
    {
      companyName: language((l) => l.experience.astor.companyName),
      workPosition: language((l) => l.experience.astor.workPosition),
      jobDescription: language((l) => l.experience.astor.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.astor.jobDescriptionBolded),
      fromDate: '07.2013',
      toDate: '08.2013',
    },
    {
      companyName: language((l) => l.experience.barak.companyName),
      workPosition: language((l) => l.experience.barak.workPosition),
      jobDescription: language((l) => l.experience.barak.jobDescription),
      jobDescriptionBolded: language((l) => l.experience.barak.jobDescriptionBolded),
      fromDate: '04.2014',
      toDate: '05.2016',
    },
  ],
  education: [
    {
      school: language((l) => l.education.master.school),
      degree: language((l) => l.education.master.degree),
      department: language((l) => l.education.master.department),
      fieldOfStudy: language((l) => l.education.master.fieldOfStudy),
      fromDate: '2015',
      toDate: '2016',
    },
    {
      school: language((l) => l.education.engineer.school),
      degree: language((l) => l.education.engineer.degree),
      department: language((l) => l.education.engineer.department),
      fieldOfStudy: language((l) => l.education.engineer.fieldOfStudy),
      fromDate: '2011',
      toDate: '2015',
    },
  ],
  achievements: [
    language((l) => l.achievements.sep),
    language((l) => l.achievements.cladCertificateLabView),
    language((l) => l.achievements.microboticsSummerWorkshop),
    language((l) => l.achievements.laureatePhysicsAndEcology),
    language((l) => l.achievements.youngScientists),
  ],
  additionSkills: [
    language((l) => l.additionSkills.projectManagementTechniques),
    language((l) => l.additionSkills.drivingLicense),
  ],
  hobby: [
    language((l) => l.hobby.creatingWebsites),
    language((l) => l.hobby.homeManagementSystem),
    language((l) => l.hobby.readingSelfDevBooks),
    language((l) => l.hobby.dancing),
    language((l) => l.hobby.photography),
  ],
});
